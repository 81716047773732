.radio{
  color: black;
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  padding: 4px 0;
  cursor: pointer;
}

.radio__input{
  display: flex;
  border: 1px solid black;
  border-radius: 100%;
}

.radio__input input{
  opacity: 0;
  width: 0;
  height: 0;;
}

.radio__control {
  display: grid;
  place-items: center;
  border: 0.15em solid var(black);
  width: 1em;
  height: 1em;
  border-radius: 50%;
}

.radio__input input:checked + .radio__control{
  border-color: currentColor;
  box-shadow: none;
}

.radio__input input:focus + .radio__control{
  /* box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor; */
}

.radio__input input + .radio__control::before{
  content: "";
  width: 0.5em;
  height: 0.5em;
  box-shadow: inset 0.5em 0.5em currentColor;
  border-radius: 50%;
  transition: 180ms transform ease-in-out;
  transform: scale(0);
}

.radio__input input:checked + .radio__control::before{
  transform: scale(1);
}

.radio__label{
  line-height: 1;
  color: var(black);
  cursor: pointer;
}

.radio:focus-within .radio__label{
  color: currentColor;
}