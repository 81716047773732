

:root{
  /* --blue: #0f69f9; */
  /* --blue-light: #009de0; */
  /* --blue-lighter:#95bdfe;  */
  --blue: #AC884D;
  --blue-light: #BA9C6B;
  --blue-lighter:#C8B088; 
  --blue-dark: #03265e;
  --blue-gray: #475779;

  --green: #00e900;
  --gray: #d8d8d8;
  --gray-light: #e4e4e4;
  --orange: #e66a04;
  --red: #C9302C;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  @font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: normal;
    src: url('./font/Gotham.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Gotham';
    font-style: bold;
    font-weight: 700;
    src: url('./font/GothamBold.ttf') format('ttf');
  }

  html{
    /* font-size: 14px; */
  }
  body{
    font-family: 'Lato', Arial, sans-serif;
  }
  h1, 
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Gotham, sans-serif;
    font-weight: bold;
  }
  h1{
    /*font-size: 2.86rem; 40px*/
    @apply text-3xl sm:text-5xl;
  }
  h2,
  h3{
    /*font-size: 2.14rem; 30px*/
    @apply text-2xl sm:text-3xl;
  }
  h3{
    font-weight: normal;
    /*font-size: 2.07rem; 29px*/
  }
  h4,
  h5{
    /*font-size: 1.5rem; 21px*/
    @apply text-xl sm:text-2xl;
  }
  h5{
    /*font-size: 1.28rem; 18px*/
    font-weight: normal;
  }
  h6{
    /*font-size: 1.15rem; 16px*/
    @apply text-lg;
  }
  input,
  textarea{
    @apply border-gray border-solid border py-2 px-3;
  }
}

@layer utilities{
  .font-head{
    font-family: Gotham, sans-serif;
  }

  .line-header{
    position: relative;
  }
  .line-header:after{
    content: "";
    background: var(--blue);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30%;
    height: 3px;
  }

  .hover-primary:hover{
    @apply text-blue;
  }

  .btn {
    @apply py-3 px-5;
  }
  .btn:focus{
    @apply outline-none shadow-inner;
  }
  .btn:disabled{
    @apply bg-gray;
    @apply cursor-not-allowed;
  }

  .btn-sm{
    @apply py-1 px-3;
  }

  .btn-primary {
    @apply bg-blue-light text-white;
  }
  .btn-primary:hover:not(:disabled) {
    @apply bg-blue;
  }
  .btn-primary:active:not(:disabled) {
    @apply bg-blue-light;
  }

  .btn-danger {
    @apply bg-red text-white;
  }
  .btn-danger:hover:not(:disabled) {
    @apply bg-orange;
  }
  .btn-danger:active:not(:disabled) {
    @apply bg-red;
  }

  .btn-default {
    @apply bg-transparent text-white border-white border-2;
  }
  .btn-default:hover:not(:disabled) {
    @apply bg-white text-black;
  }
  .btn-default:active:not(:disabled) {
    @apply bg-gray;
  }
}