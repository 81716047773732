.active {
  @apply font-bold text-xl;
}

.navItem, 
.appointment{
  font-family: Poppins;
  font-size: 1rem;
  font-weight: bold;
  line-height: 47px;
  padding-left: 15px;
  padding-right: 15px;
}
.navItem:hover{
  color: var(--blue);
}

.appointment{
  font-size: 0.9rem;
  background-color: var(--blue-light);
  color: white;
  padding: 0 20px;
}
.appointment:hover{
  background-color: var(--blue);
}